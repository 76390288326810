import React from 'react'
import Content from '../Styles/styleOne'
import { Link, SuperTag } from '~components';
import { Col, Container, Row } from 'react-bootstrap';
import img from "~assets/image/media/Sales and marketing/05.svg";

export default function Discover({}){

return(
<Content>
  
  <Container>
    <Content.Block>
    <Row className="align-items-center justify-content-center justify-content-lg-start">
    <Col className="col-xl-7 col-lg-6 col-md-8 col-xs-10">
        <Content.Image>
            <img  src={img} alt="content" layout="fullWidth" placeholder="blurred" className="w-100" height={500}/>
        </Content.Image>
      </Col>
      <Col xs="12" className="col-xl-5 col-lg-6 col-md-8 col-xs-10">
        <Content.Box>
          <Content.Title as="h2"> <SuperTag value="Discover outreach without limits"/> </Content.Title>

          <Content.Text> <SuperTag value="Take complete control of your outreach efforts with customizable action limits and campaign settings. Tailor your strategy to suit your unique needs and scale your prospecting without constraints."/> </Content.Text>
        </Content.Box>
      </Col>
    </Row>
    </Content.Block>

    
  </Container>
</Content>
)
}